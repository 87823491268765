<template>
  <v-col :cols="cols">
    <v-autocomplete
      dense
      v-model="inputVal"
      :items="items"
      outlined
      hide-no-data
      item-text="name"
      item-value="id"
      label="Eixo"
    >
    </v-autocomplete>
  </v-col>
</template>
<script>
export default {
  props: ["items", "cols"],
  computed: {
    inputVal: {
      get() {
        return this.model;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>
